import React, { Fragment } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import ChannelItem from "../components/ChannelItem"
import { videos } from "../data/efpa-channel-data"

export default function EfpaChannel() {
  const path = [{ label: "Inicio", to: "/" }, { label: "Canal EFPA" }]

  const renderContent = () => {
    return (
      videos.length &&
      videos.map(item => {
        return (
          <Fragment>
            <div className="row efpa-row">
              <TitleBlock
                className="EfpaChannel-title"
                title={item.title}
                icon="fas fa-video"
              />
              {renderVideo(item.videos)}
            </div>
          </Fragment>
        )
      })
    )
  }

  const renderVideo = videos => {
    if (!videos || !videos.length) return

    return videos.map(video => (
      <div className="col-12 col-md-6 p-0 mb-3">
        <ChannelItem url={video} />
      </div>
    ))
  }

  return (
    <Layout>
      <Hero image="/imgs/channel-hero.jpg" text="Canal EFPA" />
      <div className="container950">
        <Breadcrumb path={path} />
        <div className="EfpaChannel">{renderContent()}</div>
      </div>
    </Layout>
  )
}
