export const videos = [
  {
    title: "Sobre EFPA",
    videos: [
      `<iframe src="https://player.vimeo.com/video/378754310?color=21coe3&title=0" width="100%" height="260" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
      `<iframe src="https://player.vimeo.com/video/363510247?color=21coe3&title=0" width="100%" height="260" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
    ],
  },
  {
    title:
      "#entrevistasEFPA: La industria habla a través de EFPA sobre la digitalización en el sector financiero",
    videos: [
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/342030272" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/333111055" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/331188660" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/331188660" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/327102366" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/327102366" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/321780957" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/318519152" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/318519152" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/315210701" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/313373590" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/313373590" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/313373590" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/313373590" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
    ],
  },
  {
    title: "EFPA Congress",
    videos: [
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/328425777" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/326352043" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/323511608" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/320300629" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/320300629" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/316098583" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/314525154" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/313210771" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/310742251" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/307456980" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/299647455" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/299643865" width="100%" height="262" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/299643865" width="100%" height="262" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/299643865" width="100%" height="262" frameborder="0" allowfullscreen></iframe>`,
    ],
  },
  {
    title:
      "#entrevistasEFPA: La industria habla a través de EFPA de los impactos de MIFID II",
    videos: [
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/278651324" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/276190598" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/266840697" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/265337763" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/264047873" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/261979380" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/257927496" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/256757992" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/255765628" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/254343826" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/252171256" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/251126210" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/250102284" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
      `<iframe title="vimeo-player" src="https://player.vimeo.com/video/252312566" width="100%" height="260" frameborder="0" allowfullscreen></iframe>`,
    ],
  },
]
